import { makeAutoObservable } from "mobx"

export class Status {
  constructor() {
    makeAutoObservable(this)
  }

  dbActionProgress: string[] = []
  finishedTasks = 0
  server: string = "http://localhost:5984"
}
export const status = new Status()