import {
  faCamera,
  faCloud,
  faHandshake,
  faHome,
  faLock
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonLabel, IonTabBar, IonTabButton } from '@ionic/react'
import React, { useEffect, useState } from 'react'


const FooterTabBars: React.FC = () => {
  
  return (
    <IonTabBar slot="bottom" color="primary">
      <IonTabButton tab="tab1" href="/tabhome">
        <FontAwesomeIcon icon={faHome} size="2x" />
        <IonLabel>HOME</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab2" href="/tabnfts">
        <FontAwesomeIcon icon={faCamera} size="2x" />
        <IonLabel>NFTs</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab3" href="/tabagrees">
        <FontAwesomeIcon icon={faHandshake} size="2x" />
        <IonLabel>ACORDOS</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab4" href="/tabassets">
        <FontAwesomeIcon icon={faLock} size="2x" />
        <IonLabel>ATIVOS</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab5" href="/tabpublish">
        <FontAwesomeIcon icon={faCloud} size="2x" />
        <IonLabel>PUBLICAÇÕES</IonLabel>
      </IonTabButton>
    </IonTabBar>
  )
}
export default FooterTabBars
