import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { createRoot } from 'react-dom/client';
import {
    ApolloClient,
  InMemoryCache,
  ApolloProvider,
  
  } from "@apollo/client";

defineCustomElements(window);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!) 

const client = new ApolloClient({
    uri: process.env.REACT_APP_ENGINE_URL+"/query" || "",
    cache: new InMemoryCache()
  });

  
  
root.render(
  <ApolloProvider client={client}>    
   <App />
   </ApolloProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();

