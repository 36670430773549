import { useQuery, gql } from "@apollo/client";
import React from "react";
import { Publication } from "../../components/Publication";

const GET_PUBLICATIONS = gql`
 query GetPublications($email: String!) {
    GetPublications(email: $email) {
        id
        asset_guid
        asset_type
        status
        asset_title
        media_id
        events {
            id
            publication_id
            date
            name
            }
        }
    }
    `;

interface PublicationListProps {
    email: string
    searchTerm: string
}
 

interface PublicationType  {
    id: number
    asset_title: string
    events: {
        name: string
        date: string
        id: number
    }[]
    media_id: string
}

const filterByDate = (p1: PublicationType, p2: PublicationType) => {
    const date1 = new Date(p1.events[0].date)
    const date2 = new Date(p2.events[0].date)
    return date2.getTime() - date1.getTime()
}
  
const PublicationsList = ({email, searchTerm }: PublicationListProps) => {
    const {error, data, loading} = useQuery(GET_PUBLICATIONS, {variables: {email},
      pollInterval: 10000})
    
    if (error) {
        return (
            <div></div>
        )
    }

    if (loading) {
        return (
            <div></div>
        )
    }

    let publishs: PublicationType[] = !loading ? data.GetPublications : []
    
    let newPublishs: PublicationType[] = publishs.map((publish) => {
        return {
            id: publish.id,
            asset_title: publish.asset_title,
            events: publish.events,
            media_id: publish.media_id
        }
    } )

    newPublishs = newPublishs.sort(filterByDate)
    
    return (
    <> 
        {newPublishs
        .filter((publish) => {
            if (searchTerm == ""){
                return publish
            } else if (publish.asset_title.toLowerCase().includes(searchTerm.toLowerCase())){
                return publish
            }
        })
        .map(publish => <Publication key={publish.id} publish={publish}/> )
        
        } 
    </>
    )

}

export default PublicationsList