import React, { useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSearchbar,
  IonAccordionGroup
} from '@ionic/react'
import './TabHome.css'

import { UserContext } from '../App'
import { Redirect } from 'react-router-dom'
import { useContext } from 'react'
import Footerbars from '../components/Footerbars'
import PublicationsList from '../services/apolloclient/PublicationsList'

const Tab5: React.FC = () => {
  const [searchText, setSearchText] = useState('')
  
  const contextUser = useContext(UserContext)
  if(contextUser.logged === false){
    return(<Redirect to="/tablogin"/>)
  }

  const email = localStorage.getItem('email') || ""
  
  return (
    <IonPage>
      <IonHeader>
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          type="search"
          color="light"
          style={{ backgroundColor: 'var(--ion-color-primary)' }}
          
        />
        
      </IonHeader>
      <IonContent>
        <IonAccordionGroup>
          <PublicationsList email={email} searchTerm={searchText} />
        </IonAccordionGroup>
      </IonContent> 
      <Footerbars />
    </IonPage>
  )
}

export default Tab5
