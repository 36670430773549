import { Store } from 'pouchx'
import { EntrySchema } from './schemas'
import { Entry } from './models'

export class Entries extends Store<EntrySchema, Entry> {
  constructor({
    model,
    DBInstance,
    sort
  }: {
    model: new (DBInstance: PouchDB.Database<EntrySchema>) => Entry
    DBInstance: PouchDB.Database<EntrySchema>
    sort?: ((a: Entry, b: Entry) => number) | undefined
  }) {
    super({ model, DBInstance, sort })
  }

  // async afterAdd(item: Entry | Entry[], justOnMobx: boolean): Promise<void> {
  //   console.log('added: ', item)
  // }

  // async afterDelete(item: Entry, justOnMobx: boolean): Promise<void> {
  //   console.log('deleted: ', item)
  // }

  // async afterChange(): Promise<void> {
  //   console.log('changed: ')
  // }

}

export let entries: null | Entries = null

export function setEntriesStore(store: Entries) {
  entries = store
}
