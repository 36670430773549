import React from "react"

import {
    IonImg,
    IonItem,
    IonLabel,
    IonText,
    IonAccordion,
    IonAvatar
  } from '@ionic/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronUp, faLock } from '@fortawesome/free-solid-svg-icons'
import BoafeTracking from './BoafeTracking'
import getImage from "../utils/getImage"

interface IEvent {
  id: number
  name: string
  date: string
}

interface IPublication{
  asset_title: string
  media_id: string
  events: IEvent[]
}

interface IPublicationProps {
  publish: IPublication
}

export const Publication = ({publish}: IPublicationProps) => {

    const dateNow = new Date(Date.now())
    const datePub = new Date(publish.events[0]?.date)
    
    let date = datePub.toLocaleDateString("pt-BR").slice(0,5)
    if (dateNow.toLocaleDateString("pt-BR") === datePub.toLocaleDateString("pt-BR")) { 
      date = "Hoje"
    }

    const dateYesterday = new Date(dateNow.setMilliseconds(-86400000))

    if (dateYesterday.toLocaleDateString("pt-BR") === datePub.toLocaleDateString("pt-BR")) { 
      date = "Ontem"
    }

    return (
        <IonAccordion color="primary">
            <IonItem slot="header" color="tertiary">
              <IonAvatar slot="start" style={{ display: "flex", justifyContent: "center", alignItems: "center"  }} >
                {publish.media_id ?
                  <IonImg src={getImage(publish.media_id)} /> 
                  :
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    color="white"
                  /> 
                  }
                
              </IonAvatar>

              <IonLabel color="secondary">{publish.asset_title}</IonLabel>
              <IonLabel color="primary" slot="end">
                {date}
              </IonLabel>

              <IonText color={publish.events.length == 3 ? "completed" : "pending"} slot="end">
                <FontAwesomeIcon
                  icon={faCircleChevronUp}
                  className="ion-accordion-toggle-icon"
                  size="lg"
                />
              </IonText>
            </IonItem>

            <BoafeTracking events={publish.events} />
        </IonAccordion>
    )
}