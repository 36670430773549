import { setupIonicReact } from '@ionic/react'
import React, { useContext, useState } from 'react'
import { Route } from 'react-router-dom'
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

/* Global CSS */
import './global.css'
import MainTabs from './components/MainTabs'
import { getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'


//import { registerStores } from './stores/boafe'

setupIonicReact({})
interface IUserManager {
  setIsLoggedIn: Function
  setIsRegistered: Function
  logged: boolean
  user: any
}

const user: IUserManager = {
  setIsLoggedIn: () => {},
  setIsRegistered: () => {},
  logged: false,
  user: {}
}

export const UserContext = React.createContext<IUserManager>(user)
const IonicApp: React.FC = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyD6G3TTwX8IBqZCrsvZoZvd9go3Aut4xt0',
    authDomain: 'app-boafe.firebaseapp.com',
    projectId: 'app-boafe',
    storageBucket: 'app-boafe.appspot.com',
    messagingSenderId: '421357603528',
    appId: '1:421357603528:web:61eea96746acbf86cb33fd',
    measurementId: 'G-BFTNBN6B70'
  }

  // Initialize Firebase
  initializeApp(firebaseConfig)

  const auth = getAuth()

  let log = false
  if (
    localStorage.getItem('logged') &&
    localStorage.getItem('logged') === 'true'
  ) {
    log = true
  } else {
    log = false
  }

  const [isLoggedIn, setIsLoggedIn] = useState(log)
  const user = useContext(UserContext)

  user.setIsLoggedIn = setIsLoggedIn
  user.logged = isLoggedIn

  return (
    <IonApp>
      <IonReactRouter>
        {/* <Route path="/tablogin" component={TabLogin}  /> */}
        <Route path="/" component={MainTabs} />
      </IonReactRouter>
    </IonApp>
  )
}

const App: React.FC = () => {
  return (
    <UserContext.Provider value={user}>
      
      <IonicApp />
    </UserContext.Provider>
  )
}

//   <PhotoStoreProvider>
//     <StoreProvider>
//       <IonApp>
//       <UserContext.Provider value={user}>
//       <IonicApp />
//     </UserContext.Provider>
//       </IonApp>
//     </StoreProvider>
//   </PhotoStoreProvider>
// )

export default App
