import { Geolocation } from '@awesome-cordova-plugins/geolocation'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonCardTitle,
  IonText,
  IonButton,
  IonModal,
  IonItem,
  IonInput
} from '@ionic/react'

import { camera, calendar, location, cloud, closeSharp } from 'ionicons/icons'
import { usePhotoGallery } from '../hooks/usePhotoGallery'
import './TabNfts.css'

import { customAlphabet } from 'nanoid'
import { Controller, useForm } from 'react-hook-form'
import { useStore } from '../stores/boafe/rootStore'
import { Entry, EntrySchema, EntryType } from '../stores/boafe'
import { observer } from 'mobx-react-lite'
import { UUID } from 'pouchx/dist/lib/uuid'
import { Storage } from '@capacitor/storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'



import { UserContext } from '../App'
import { Redirect } from 'react-router-dom'
import Footerbars from '../components/Footerbars'
import { createSimpleNftContract } from '../services/api'

const nanoid = customAlphabet('1234567890abcdef', 16)
// const POSITIONSTACK_ACCESS_KEY = process.env.REACT_APP_POSITIONSTACK_ACCESS_KEY
const ENGINE_URL = process.env.REACT_APP_ENGINE_URL

const TabNfts: React.FC = () => {

  const { takePhoto } = usePhotoGallery()
  const [goCreate, setGoCreate] = useState(false)
  const [suggestLocal, setSuggestLocal] = useState('')
  const [currentPhotoPath, setCurrentPhotoPath] = useState('')
  const [currentPhotoId, setCurrentPhotoId] = useState('')
  const [currentDate] = useState(new Date().toISOString().substring(0, 10))
  const entries = useStore()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lenDocs, setLenDocs] = useState(entries.docs?.length ?? 0)

  type FormInput = {
    title: string
    date: string
    local: string
    media: string
  }

  const { control, handleSubmit, reset, formState } = useForm<FormInput>()

  const setCachePositionResult = async (
    positionQuery: string,
    cachedResult: any
  ) => {
    await Storage.set({
      key: positionQuery,
      value: JSON.stringify(cachedResult, null, '  ')
    })
  }
  
  const getCachePositionResult = async (
    positionQuery: string
  ): Promise<any> => {
    const result = await Storage.get({ key: positionQuery })
    if (!result) {
      return null
    }
    try {
      return JSON.parse(result.value!)
    } catch (err) {
      console.log('RESULT VALUE:', result.value)
      console.log('ERRO:', err)
      return null
    }
  }

  const getSuggestLocal = useCallback(async () => {
    const cp = await Geolocation.getCurrentPosition()
    const positionQuery = `${Math.round(cp.coords.latitude * 1000) / 1000},${
      Math.round(cp.coords.longitude * 1000) / 1000
    }`
    console.log('position query: ', positionQuery)

    let cachedResult = await getCachePositionResult(positionQuery)
    console.log('cachedResult:', JSON.stringify(cachedResult, null, '  '))

    if (!cachedResult) {
      // const response = await fetch(
      //   'http://api.positionstack.com/v1/reverse' +
      //     `?access_key=${POSITIONSTACK_ACCESS_KEY}` +
      //     `&query=${positionQuery}` +
      //     `&limit=1&output=json`
      // )

      const response = await fetch(
        ENGINE_URL + '/geodata/reverse' +
          `?query=${positionQuery}`
      )

      const cachedResults = await response.json()
      if (cachedResults && Array.isArray(cachedResults.data)) {
        cachedResult = cachedResults.data[0]
      }

      setCachePositionResult(positionQuery, cachedResult!)
    }

    console.log('cachedResult:', JSON.stringify(cachedResult, null, '  '))

    let locs = [cachedResult.neighbourhood, cachedResult.county, cachedResult.region_code]
    locs = locs.filter(loc => loc !== null)

    return locs.join(" - ")
  }, [])

  const takeHandler = async () => {
    setCurrentPhotoPath('')
    const [photoId, photoPath, base64Data] = await takePhoto()
    setCurrentPhotoId(photoId)
    if (photoId && photoPath) {
      setCurrentPhotoPath(base64Data!)
      setGoCreate(true)
      setIsModalOpen(true)
    }
  }

  entries.afterChange = async () => {
    setLenDocs(entries.docs.length)
  }

  useEffect(() => {
    async function getNfts() {
      setSuggestLocal(await getSuggestLocal())
      await entries.updateFromPouch()
      setLenDocs(entries.docs.length)
    }
    getNfts()
  }, [entries, getSuggestLocal])

  // const getAttachment = (mediaId: string): UserPhoto => {
  //   const photo = photos.filter((photo) => photo.id === mediaId)[0]
  //   return photo
  // }

  const createNft = async (input: FormInput) => {
    ;(async () => {
      if (!entries) {
        return
      }
      input.media = currentPhotoPath

      const nft: EntrySchema = {
        _id: nanoid(),
        id: UUID(),
        timestamp: new Date().getTime(),
        title: input.title,
        local: input.local,
        media: input.media,
        mediaId: currentPhotoId,
        date: new Date(input.date),
        entryType: EntryType.InstaNft
      }

      const entry = entries.new(nft)
      await entries.add(entry)

      // const email = localStorage.getItem('email')
      // const uid = localStorage.getItem('uid')
      // const wallet = localStorage.getItem('wallet')
      //await cms.createNft(nft._id)

      await createSimpleNftContract(nft._id)
    })()

    setGoCreate(false)
    reset()
    setIsModalOpen(false)
  }

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  interface ListNftsProps {
    docs: Entry[]
  }

  const ListNfts = observer<ListNftsProps>(({ docs }) => {
    const nfts = entries.docs.filter(doc => doc.entryType === "insta_nft")

    if (nfts)
      return (
        <>
          {docs?.map((doc, index) => (
            <IonCard key={index} color="tertiary" style={{ cursor: 'pointer' }}>
              <IonImg src={doc.media} />

              <IonCardHeader>
                <IonCardTitle>
                  <IonText color="secondary">{doc.title}</IonText>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <div>
                  <IonLabel color="primary">
                    <IonIcon icon={calendar} slot="start" />
                    &nbsp;{new Date(doc.date).toLocaleDateString('pt-BR')}
                  </IonLabel>
                </div>
                <div>
                  <IonLabel color="primary">
                    <IonIcon icon={location} slot="start" />
                    &nbsp;{doc.local}
                  </IonLabel>
                </div>
              </IonCardContent>
              
            </IonCard>
            
          ))}
        </>
      )
    return <></>
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const contextUser = useContext(UserContext)
  if(contextUser.logged === false){
    return(<Redirect to="/tablogin"/>)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary" style={{ boxShadow: 'none' }}>
          <IonTitle>NFTs Instantâneos</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonModal
        className="app-modal"
        isOpen={isModalOpen && goCreate}
        canDismiss={true}
      >
        {goCreate ? (
          <>
            <div className="app-modal-header">
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => setIsModalOpen(false)}
              />
            </div>

            <IonContent color="tertiary">
              <form onSubmit={handleSubmit(createNft)}>
                <IonCard>
                  <div
                    className="app-image-preview"
                    style={{ backgroundImage: `url(${currentPhotoPath}` }}
                  ></div>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="stacked" color="primary">
                        Título
                      </IonLabel>

                      <Controller
                        render={({ field, fieldState, formState }) => (
                          <IonInput
                            {...field}
                            onIonBlur={field.onBlur}
                            value={field.value}
                            onInput={field.onChange}
                            onIonChange={field.onChange}
                            placeholder="(Dê um título para esse momento)"
                            color={fieldState.error ? 'danger' : ''}
                          ></IonInput>
                        )}
                        control={control}
                        name="title"
                        defaultValue=""
                        rules={{ required: true }}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked" color="primary">
                        Data
                      </IonLabel>
                      <Controller
                        render={({ field, fieldState }) => (
                          <IonInput
                            type="date"
                            onIonBlur={field.onBlur}
                            value={field.value}
                            onInput={field.onChange}
                            onIonChange={field.onChange}
                            placeholder="(Defina uma data)"
                            color={fieldState.error ? 'danger' : ''}
                          />
                        )}
                        control={control}
                        name="date"
                        rules={{ required: true }}
                        defaultValue={currentDate}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel
                        position="stacked"
                        color="primary"
                        defaultValue={suggestLocal}
                      >
                        Local
                      </IonLabel>
                      <Controller
                        render={({ field, fieldState }) => (
                          <IonInput
                            onIonBlur={field.onBlur}
                            value={field.value}
                            onInput={field.onChange}
                            onIonChange={field.onChange}
                            placeholder="(Defina um local)"
                            color={fieldState.error ? 'danger' : ''}
                          />
                        )}
                        control={control}
                        name="local"
                        defaultValue={suggestLocal}
                        rules={{ required: true }}
                      />
                    </IonItem>
                  </IonCardContent>
                </IonCard>
                <div className="app-modal-footter-action">
                  <IonButton
                    expand="block"
                    type="submit"
                    disabled={
                      formState.isDirty &&
                      Object.keys(formState.errors).length > 0
                    }
                  >
                    Publicar
                    <IonIcon slot="start" icon={cloud} />
                  </IonButton>
                </div>
              </form>
            </IonContent>
          </>
        ) : (
          <></>
        )}
      </IonModal>

      <IonContent>
        <ListNfts
          docs={entries.docs.sort((a, b) => b.timestamp - a.timestamp).filter((doc) => doc.entryType === "insta_nft")}
        />

        <IonModal isOpen={isDetailsModalOpen} canDismiss={true}>
          <div
            style={{
              cursor: 'pointer',
              width: 'fit-content',
              padding: '20px'
            }}
            onClick={() => setIsDetailsModalOpen(false)}
          >
            <IonIcon
              slot="start"
              size="large"
              icon={closeSharp}
              style={{ color: '#000' }}
            />
          </div>
          <h1>{isDetailsModalOpen}</h1>
        </IonModal>

        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton onClick={takeHandler}>
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
      <Footerbars />
    </IonPage>
  )
}

export default TabNfts
