import { useState, useEffect } from 'react'
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo
} from '@capacitor/camera'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'
import { usePhotoStore } from '../stores/boafe/rootStore'
import { UUID } from 'pouchx/dist/lib/uuid'

const PHOTO_STORAGE = 'photos'
export function usePhotoGallery() {
  const store = usePhotoStore()
  const [photos, setPhotos] = useState<UserPhoto[]>([])

  useEffect(() => {
    const loadSaved = async () => {
      const photosInStorage: UserPhoto[] = []
      const docs = await store.allDocs<UserPhoto>()
      docs.rows.forEach((row) => {
        store.get<UserPhoto>(row.id).then((doc) => {
          photosInStorage.push(doc)  
          setPhotos(photosInStorage)
        })
      })
    }
    loadSaved()
  }, [store])

  const takePhoto = async (): Promise<string[]> => {
    let photo: Photo | null = null
    try {
      photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100
      })
    } catch {}

    if (photo) {
      const blob = await blobFromPath(photo.webPath!)
      const base64Data = await base64FromPath(photo.webPath!)

      const id = UUID()
      const doc = {
        _id: id,
        _attachments: {
          'media': {
            content_type: 'image/jpeg',
            data: blob
          }
        },
        id: id,

        timestamp: (new Date().getTime()),
        filepath: photo.path,
        webviewPath: photo.webPath,
        base64Data: base64Data
      }

  
      store.put(doc)

      return [id, photo.webPath!, base64Data]
    }

    return []
  }

  //   const savePicture = async (
  //     photo: Photo,
  //     fileName: string
  //   ): Promise<UserPhoto> => {
  //     let base64Data: string
  //     // "hybrid" will detect Cordova or Capacitor;
  //     if (isPlatform('hybrid')) {
  //       const file = await Filesystem.readFile({
  //         path: photo.path!
  //       })
  //       base64Data = file.data
  //     } else {
  //       base64Data = await base64FromPath(photo.webPath!)
  //     }
  //     const savedFile = await Filesystem.writeFile({
  //       path: fileName,
  //       data: base64Data,
  //       directory: Directory.Data
  //     })

  //     if (isPlatform('hybrid')) {
  //       // Display the new image by rewriting the 'file://' path to HTTP
  //       // Details: https://ionicframework.com/docs/building/webview#file-protocol
  //       return {
  //         filepath: savedFile.uri,
  //         webviewPath: Capacitor.convertFileSrc(savedFile.uri)
  //       }
  //     } else {
  //       // Use webPath to display the new image instead of base64 since it's
  //       // already loaded into memory
  //       return {
  //         filepath: fileName,
  //         webviewPath: photo.webPath
  //       }
  //     }
  //   }

  const deletePhoto = async (photo: UserPhoto) => {
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter((p) => p.filepath !== photo.filepath)

    // Update photos array cache by overwriting the existing photo array
    Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) })

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1)
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data
    })
    setPhotos(newPhotos)
  }

  return {
    deletePhoto,
    photos,
    takePhoto
  }
}

export interface UserPhoto {
  id: string
  timestamp: number,
  filepath: string
  webviewPath?: string
  base64Data?: string
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path)
  const blob = await response.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject('method did not return a string')
      }
    }
    reader.readAsDataURL(blob)
  })
}

export async function blobFromPath(path: string) {
  const response = await fetch(path)
  const blob = await response.blob()
  return blob
}
