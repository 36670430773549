import {
  IonCard,
  IonItem,
  IonIcon,
  IonText,
  IonRippleEffect
} from '@ionic/react'
import { add } from 'ionicons/icons'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface BoafeMainItemProps {
  icon: IconDefinition
  title: string
  subtitle: string
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

const BoafeMainItem: React.FC<BoafeMainItemProps> = (props) => {
  return (
    <IonCard style={{ cursor: 'pointer' }} className="ion-activatable" disabled={props.disabled} onClick={props.onClick}>
      <IonItem color="tertiary">
        <div className="app-icon" slot="start" color="primary">
          <FontAwesomeIcon icon={props.icon} size="2x" color="white" />
          <IonRippleEffect />
        </div>
        <IonText>
          <IonText color="secondary" className="app-title">
            <strong>{props.title}</strong>
          </IonText>
          <br />
          <IonText color="primary" className="app-subtitle">{props.subtitle}</IonText>
        </IonText>
        <IonIcon className="app-home-plus" slot="end" icon={add} color="primary" />
      </IonItem>
      <IonRippleEffect />
    </IonCard>
  )
}

export default BoafeMainItem
