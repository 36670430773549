import React, { useEffect, useState } from 'react'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'

import { UserContext } from '../App'
import { Redirect } from 'react-router-dom'
import { useContext } from 'react'
import request, { gql } from 'graphql-request'

const ENGINE_URL = process.env.REACT_APP_ENGINE_URL

const TabAgrees: React.FC = () => {
  const contextUser = useContext(UserContext)
  const [walletData, setWalletData] = useState('')
  const [wallet, setWallet] = useState('')

  useEffect(() => {
    ;(async () => {
      const query = gql`
        query ExportWallet($input: WalletInput!) {
          ExportWallet(input: $input) {
            publicKey
            phrase
            qrcode
          }
        }
      `
      const email = localStorage.getItem('email') as string
      const uid = localStorage.getItem('uid') as string
      const wallet = localStorage.getItem('wallet') as string

      const data = await request(`${ENGINE_URL}/query`, query, {
        input: {
          email: email as string,
          uid: uid
        }
      })
      setWalletData(data?.ExportWallet?.qrcode)
      setWallet(wallet)
    })()
  })

  if (contextUser.logged === false) {
    return <Redirect to="/tablogin" />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Exportar carteira</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonCard>
          <IonImg src={walletData} />
          <IonCardHeader>
            <IonCardTitle color="secondary">{wallet}</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default TabAgrees
