import { Model } from 'pouchx'
import { EntrySchema, EntryType, PublicationSchema, PublicationTracking, TrackingItemSchema } from './schemas'
import { makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from 'uuid'
import { PublicationStatus } from './schemas';
import { UUID } from 'pouchx/dist/lib/uuid';


export type EntryInput = {
  title: string
  date: Date
  local: string
  media: string
  entryType: EntryType
}

export class Entry extends Model<EntrySchema> implements EntrySchema {
  constructor(db: PouchDB.Database<EntrySchema>){
    super(db)
    this.id = UUID()
    this.timestamp = 0
    this.title = ''
    this.date = new Date()
    this.local = ''
    this.media = ''
    this.mediaId = ''
    this.entryType = EntryType.InstaNft

    makeObservable(this, {
      id: observable,
      timestamp: observable,
      title: observable,
      date: observable,
      local: observable,
      media: observable,
      mediaId: observable,
      entryType: observable
    })
  }
  
  id: string
  timestamp: number
  title: string
  date: Date
  local: string
  media: string
  mediaId: string
  entryType: EntryType


  fromJSON(json: EntrySchema): Model<EntrySchema> {
    this._id = json._id
    this.id = json.id
    this.timestamp = json.timestamp
    this.title = json.title
    this.date = json.date
    this.local = json.local
    this.media = json.media
    this.mediaId = json.mediaId
    this.entryType = json.entryType

    return this
  }

  toJSON(): EntrySchema {
    return {
      _id: this._id,
      id: this.id,
      timestamp: this.timestamp,
      title: this.title,
      date: this.date,
      local: this.local,
      media: this.media,
      mediaId: this.mediaId,
      entryType: this.entryType
    }
  }
}


export class Publication extends Model<PublicationSchema> implements PublicationSchema {
  constructor(entry: EntrySchema, db: PouchDB.Database<PublicationSchema>){
    super(db)

    this.id = uuidv4()
    this.entry = entry
    this.trackingTracking = PublicationTracking.Created
    this.publicationStatus = PublicationStatus.Uninitialized
    this.log = []
    
    makeObservable(this, {
      id: observable,
      entry: observable,
      trackingTracking: observable,
      publicationStatus: observable,
      log: observable
    })
  }

  id: string
  entry: EntrySchema 
  trackingTracking: PublicationTracking
  publicationStatus: PublicationStatus
  log: TrackingItemSchema[]


  fromJSON(json: PublicationSchema): Model<PublicationSchema> {
    this._id = json._id
    this.id = json.id
    this.entry = json.entry
    this.trackingTracking = json.trackingTracking
    this.publicationStatus = json.publicationStatus
    this.log = json.log

    return this
  }

  toJSON(): PublicationSchema {
    return {
      _id: this._id,
      id: this.id,
      entry: this.entry,
      trackingTracking: this.trackingTracking,
      publicationStatus: this.publicationStatus,
      log: this.log
    }
  }
}
