import axios from 'axios'
import { EntrySchema } from '../stores/boafe'
import PouchDB from 'pouchdb'

export const createSimpleNftContract = async(nftId: string) => {
    const entries = new PouchDB<EntrySchema>('entries')
    const nft = await entries.get<EntrySchema>(nftId)

    if (!nft) {
        return {
          cmsEntryId: undefined,
          hasError: true,
          errors: ['nft not found']
        }
    }
    const dbPhotos = new PouchDB('photos')
    const attachment = await dbPhotos.getAttachment(nft.mediaId, 'media') as Blob
    if (!attachment) {
    return {
        cmsEntryId: undefined,
        hasError: true,
        errors: ['photo not found']
    }
    }

    const typeExtention = attachment.type.split("/")[1].split("+")[0] || "jpg"
    const fileName = nft.title.replace(" ", "_").toLowerCase() + "." + typeExtention
    
    const email = localStorage.getItem('email')
    const uid = localStorage.getItem('uid')
    const wallet = localStorage.getItem('wallet') || ''

    const createSimpleNftContractData = {
        query: "mutation ($input: CreateSimpleNftContractInput!) {CreateSimpleNftContract(input: $input) {ref}}",
        variables: {
            input: {
                owner_public_key: wallet,
                file: "0",
                name: nft.title,     
                properties: [
                        {name: 'date',value: nft.date},
                        {name: 'local',value: nft.local},
                        {name: 'email',value: email},
                        {name: 'uid',value: uid},
                ]
            }
        }
    }

    const operations = JSON.stringify(createSimpleNftContractData)

    const map = {
        "0": ["variables.input.file"]
    }

    const form = new FormData()
    form.append("operations", operations)
    form.append("map", JSON.stringify(map))
    form.append("0", attachment as Blob, fileName)

    try{
        const url = process.env.REACT_APP_ENGINE_URL+"/query" || ""
        const resp = await axios({
            url: url,
            method: "POST",
            data: form
        })
        console.log(resp)

    }catch(err){
        console.log(err)
    }
}

export const createSafeAssetContract = async(safeAssetId: string) => {
    const entries = new PouchDB<EntrySchema>('entries')
    const safeAsset = await entries.get<EntrySchema>(safeAssetId)

    if (!safeAsset) {
        return {
          cmsEntryId: undefined,
          hasError: true,
          errors: ['safeAsset not found']
        }
    }

    const dbPhotos = new PouchDB('photos')
    const attachment = await dbPhotos.getAttachment(safeAsset.mediaId, 'media') as Blob
    if (!attachment) {
    return {
        cmsEntryId: undefined,
        hasError: true,
        errors: ['photo not found']
    }
    }
   
    const email = localStorage.getItem('email')
    const uid = localStorage.getItem('uid')
    const wallet = localStorage.getItem('wallet') || ''

    const typeExtention = attachment.type.split("/")[1].split("+")[0] || "jpg"
    const fileName = safeAsset.title.replace(" ", "_").toLowerCase() + "." + typeExtention
    
    const createSimpleSafeAssetContractData = {
        query: "mutation ($input: CreateSafeAssetContractInput!) {CreateSafeAssetContract(input: $input) {ref}}",
        variables: {
            input: {
            owner_public_key: wallet,
            file: "0",
            name: safeAsset.title,
            properties: [
                {name: 'date',value: safeAsset.date},
                {name: 'local',value: safeAsset.local},
                {name: 'email',value: email},
                {name: 'uid',value: uid},
    
            ]
            
                }
        }
    }

    const operations = JSON.stringify(createSimpleSafeAssetContractData)

    const map = {
        "0": ["variables.input.file"]
    }

    const form = new FormData()
    form.append("operations", operations)
    form.append("map", JSON.stringify(map))
    form.append("0", attachment as Blob, fileName)

    try{
        const url = process.env.REACT_APP_ENGINE_URL+"/query" || ""
        const resp = await axios({
            url: url,
            method: "POST",
            data: form
        })
        console.log(resp)

    }catch(err){
        console.log(err)
    }
}