import React from 'react'
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonContent  
} from '@ionic/react'
import { useContext } from 'react'
import { UserContext } from '../App'
import { Redirect } from 'react-router-dom'

const Details: React.FC = () => {
  const contextUser = useContext(UserContext)
  if(contextUser.logged === false){
    return(<Redirect to="/tablogin"/>)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabnft" />
          </IonButtons>
          <IonTitle>Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p>Details</p>
      </IonContent>
    </IonPage>
  )
}

export default Details
