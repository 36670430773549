import { Schema } from "pouchx"

export enum EntryType{
  InstaNft = 'insta_nft',
  AgreeNft = 'agree_nft',
  PrivateNft = 'private_nft',
}

export interface EntrySchema extends Schema {
  id: string
  timestamp: number,
  title: string
  date: Date
  local: string
  media: string
  mediaId: string
  entryType: EntryType
}


export enum PublicationStatus {
  Uninitialized = "uninitialized",
  Pending = 'pending',
  Success = 'success',
  Fail = 'fail'
}

export enum PublicationTracking {
  Created = 'data_created',
  Requested = 'transaction_requested',
  Signed = 'transaction_signed',
  IpfsPublished = 'ipfs_published',
  BlockchainPublished = 'blockchain_published',
}

export interface TrackingItemSchema extends Schema {
  code : string
  timestamp: number
  label: string
  description: string
}

export interface PublicationSchema extends Schema {
  id: string
  entry: EntrySchema
  trackingTracking: PublicationTracking
  publicationStatus: PublicationStatus
  log: TrackingItemSchema[]
}

