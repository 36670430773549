import React from 'react'
import { Entry } from '.'
import { Entries } from './stores'
import PouchDB from 'pouchdb';

const initialEntries: Entries = new Entries({
  model: Entry,
  DBInstance: new PouchDB('entries')
})

const storeContext = React.createContext<Entries | null>(initialEntries)
const photoStoreContext = React.createContext<PouchDB.Database<{}> | null>(new PouchDB('photos'))

export const StoreProvider = ({ children }: any) => {
  const store = initialEntries //useLocalStore(createStore) 
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

export const PhotoStoreProvider = ({ children }: any) => {
  const db = new PouchDB('photos') //useLocalStore(createStore) 
  return <photoStoreContext.Provider value={db}>{children}</photoStoreContext.Provider>
}

export const usePhotoStore = () => {
  const store = React.useContext(photoStoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}