import React from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import './TabAgrees.css'
import { Redirect } from 'react-router-dom'
import { useContext } from 'react'

import { UserContext } from '../App'
import Footerbars from '../components/Footerbars'

const TabAgrees: React.FC = () => {

  const contextUser = useContext(UserContext)
  if(contextUser.logged === false){
    return(<Redirect to="/tablogin"/>)
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Acordos Inteligentes</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard>
          <IonImg
            src="/assets/logo_provisorio_boafe_sem_fundo.svg"
            style={{ backgroundColor: 'var(--ion-color-tertiary)' }}
          />

          <IonCardHeader>
            <IonCardSubtitle>em breve</IonCardSubtitle>
            <IonCardTitle color="secondary">Acordos Inteligentes</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <p>Protegidos e reconhecidos por lei.</p>
            <p>
              Envie e receba a transferência de propriedade para uma ou mais
              pessoas.
            </p>
            <p>Assine usando sua carteira cripto.</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <Footerbars />
    </IonPage>
  )
}

export default TabAgrees
