import { setupIonicReact } from '@ionic/react'
import { PhotoStoreProvider, StoreProvider } from '../stores/boafe/rootStore'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabs
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import TabHome from '../pages/TabHome'
import TabPublish from '../pages/TabPublish'
import NftDetails from '../pages/NftDetails'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import '../theme/variables.css'

/* Global CSS */
import '../global.css'
import TabAgrees from '../pages/TabAgrees'
import TabAssets from '../pages/TabAssets'
import TabNfts from '../pages/TabNfts'
import TabLogin from '../pages/TabLogin'
import TabRegister from '../pages/TabRegister'
import TabRecovery from '../pages/TabRecovery'
import ModalWallet from '../pages/ModalWallet'

//import { registerStores } from './stores/boafe'

setupIonicReact({})

//registerStores()
const MainTabs: React.FC = () => {
  return (
    <PhotoStoreProvider>
      <StoreProvider>
        <IonApp>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/tabhome" component={TabHome} exact={true} />
                <Route path="/tabnfts" component={TabNfts} exact={true} />
                <Route path="/tabnfts/details" component={NftDetails} />
                <Route path="/tabagrees" component={TabAgrees} />
                <Route path="/tabassets" component={TabAssets} />
                <Route path="/tabpublish" component={TabPublish} />
                <Route path="/tablogin" component={TabLogin} />
                <Route path="/tabregister" component={TabRegister} />
                <Route path="/tabrecovery" component={TabRecovery} />
                <Route path="/modalwallet" component={ModalWallet} />

                <Route
                  path="/"
                  render={() => <Redirect to="/tablogin" />}
                  exact={true}
                />
              </IonRouterOutlet>

              <IonTabBar >
               
              </IonTabBar>
            </IonTabs>
          </IonReactRouter>
        </IonApp>
      </StoreProvider>
    </PhotoStoreProvider>
  )
}

export default MainTabs
