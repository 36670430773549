import React from "react"
interface IEvent {
  id: number
  name: string
  date: string
}

interface IBoafeTrackingProps {
  events: IEvent[]
}

const BoafeTacking = ({events}:IBoafeTrackingProps) => {
  
  return (
    <div className="app-tracking" slot="content">
      <div className={events[0] ? "app-tracking-done": ""}>
        <div></div>
        <div>{events[0]?.date.split(" ")[1].slice(0,5)}</div>
        <div>imagem capturada</div>
      </div>

      <div className={events[1] ? "app-tracking-done": ""}>
        <div></div>
        <div>{events[1]?.date.split(" ")[1].slice(0,5)}</div>
        <div>publicação solicitada</div>
      </div>

      <div className={events[2] ? "app-tracking-done": ""}>
        <div></div>
        <div>{events[2]?.date.split(" ")[1].slice(0,5)}</div>
        <div>publicação autorizada</div>
      </div>

      <div className={events[2] ? "app-tracking-done": ""}>
        <div></div>
        <div>{events[2]?.date.split(" ")[1].slice(0,5)}</div>
        <div>imagem eternizada</div>
      </div>

      <div className={events[2] ? "app-tracking-done": ""}>
        <div></div>
        <div>{events[2]?.date.split(" ")[1].slice(0,5)}</div>
        <div>informações eternizadas</div>
      </div>
    </div>
  )
}

export default BoafeTacking
